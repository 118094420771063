<template>
  <section v-if="isLoggedIn" class="container py-5"> 
    <h2>{{$t('profile-header')}}</h2>
    <div class='col-md-6 mx-auto'>
      <div v-if="lang === 'en'" class='row row-cols-1 row-cols-sm-2 mb-5'>
          <div v-for="(item, index) in enOptions" :key="index" class="card mx-auto my-2">
              <div class="card-body">
                  <h3 class="card-title">{{ item.title }}</h3>
                  <p class="card-text">{{ item.body }}</p>
              </div>
          </div>
      </div>
      <div v-if="lang === 'hi'" class='row row-cols-1 row-cols-sm-2 mb-5'>
          <div v-for="(item, index) in hiOptions" :key="index" class="card mx-auto my-2">
              <div class="card-body">
                  <h3 class="card-title">{{ item.title }}</h3>
                  <p class="card-text">{{ item.body }}</p>
              </div>
          </div>
      </div>
      <!-- <hr/> -->
    </div>
    <!-- <div class='col-8 col-sm-6 mx-auto mt-5'>
      <h3>Dangerous Settings</h3>
      <a role='button' id='delete-link' @click='deleteConfirmation()'>Delete account</a>
      <div class='d-none mt-3 col-lg-8 mx-auto' id='delete-confirmation'>
        <h4>Are you sure?<br/>We'd hate to see you go. 😢</h4>
        <button class='btn btn-danger my-2' @click='deleteAccount()'>Yes, delete my account</button>
        <p style="color:#dc3545"><strong>Warning:</strong> Clicking the delete button will remove all information associated with your account from our servers. This cannot be undone.</p>
      </div>
    </div> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import firebase from 'firebase/app';

export default {
  name: 'Settings',
  metaInfo: {
    title: 'Settings',
    titleTemplate: '%s | Chachi',
  },
  data: function() {
    return {
        enOptions: [
            {title: "Name", body: ""},
            {title: "Profession", body: ""},
            // {title: "Location", body: ""},
            // {title: "Pro Subscription", body: ""},
        ],
        hiOptions: [
            {title: "नाम", body: ""},
            {title: "पेशा", body: ""},
            // {title: "Location", body: ""},
            // {title: "Pro Subscription", body: ""},
        ]
    };
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      isLoggedIn: state => state.isLoggedIn,
      currentUser: state => state.currentUser
    })
  },
  mounted() {
    if (this.lang === 'en') {
      this.enOptions[0].body = this.currentUser.firstName
      this.enOptions[1].body = this.currentUser.profession
      // this.enOptions[2].body = this.currentUser.state
      // this.enOptions[3].body = this.currentUser.proUser
    } else if (this.lang === 'hi') {
      this.hiOptions[0].body = this.currentUser.firstName
      this.hiOptions[1].body = this.currentUser.profession
      // this.hiOptions[2].body = this.currentUser.state
      // this.hiOptions[3].body = this.currentUser.proUser
    }
  },
  methods: {
    deleteConfirmation() {
      document.getElementById('delete-confirmation').classList.toggle('d-none')
    },
    deleteAccount() {
      const uid = this.currentUser.uid
      const token = this.$cookies.get('user-jwt')
      const config = { headers: { "Authorization": `Bearer ${token}`, "Access-Control-Expose-Headers": "Authorization" } };
      var api_link = 'https://api.chachi.app/v2/users/destroy?user=' + encodeURI(uid)
      axios.delete(api_link, config)
        .then( response => {
          if (response.data.deleted) { console.log('user gone') }
        })
        .catch (err => {
          if (err.response.data.detail === "Invalid token or expired token.") {
            var newTokenSet = this.$store.dispatch('setUserJWT')
            if (newTokenSet) {this.deleteAccount()}
          }
        })
      
      firebase.auth().signOut()
        .then(() => {
          localStorage.removeItem('vuex')
          this.$router.go({ path: this.$router.path });
        });
    },
    fetchAgain() {
      this.$store.dispatch("setCurrentUser")
    }
  }
};
</script>

<style scoped>
.card {
	border-radius: 8px;
  width: 16rem;
}
@media screen and (max-width: 768px) {
    .space-out {
        padding-top: 3rem !important;
    }
    .card {
      width: 18rem;
    }
}
a {
  text-decoration: none;
}
#delete-link {
  text-decoration: underline;
  color:#dc3545;
}
</style>